export const navigations = [
  { name: 'Dashboard', path: '/dashboard', icon: 'th' },
  { name: 'View Profile', path: '/user/[username]', icon: 'id-card' },
  { name: 'Edit Profile', path: '/profile/edit', icon: 'edit' },
  { name: 'Messages', path: '/dashboard/messages', icon: 'envelope', badge:'messages' },
  { name: 'Friends', path: '/dashboard/friends', icon: 'user-friends', badge:'requests' },
  { name: 'Profile Visitors', path: '/dashboard/visitors', icon: 'user-circle', badge:'visitors' },
  { name: 'Notifications', path: '/dashboard/notifications', icon: 'bell', badge:'notifications' },
 // { name: 'Settings', path: '/dashboard/settings', icon: 'cog' },
  { name: 'Peoples', path: '/users', icon: 'users' },
  { name: 'Places', path: '/places', icon: 'map-marker-alt' },
//  { name: 'Blogs', path: '/blogs', icon: 'blog' },
 // { name: 'News', path: '/news', icon: 'newspaper:r' },
];
